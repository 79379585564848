@use '../../scss' as *;

.etharis_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;


    .tf-button.style1 {
        background-color: #fb6357;
        margin: 0 10px;
    }

    .tf-button.style1:hover {
        background-color: #be7123;
        color: white;
    }

    @include res400 { 
        display: block;
        .tf-button.style1 {
            margin: 20px 10px;
        }
    }
}